import { Token } from '@/ts/Token'


export abstract class Authentication{

    public static isSignedIn(): boolean {
        let token = this.getToken();
        if (!token || !token.functionCodeAdmin) {
            return false;
        }
        return true;
    }

    public static isAdmin(): boolean {
        let token = this.getToken();
        if (!token || !token.role || token.role !== "admin") {
            return false;
        }
        return true;
    }

    public static parseJwt(token): Token {
        try {
            return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
            return null;
        }
    };

    public static functionCodeAdmin(): string {
        let token = this.getToken();
        if (!token || !token.functionCodeAdmin) {
            return "";
        }

        return token.functionCodeAdmin;
    }

    public static getToken(): Token {
        let token = null;

        try {
            token = this.parseJwt(sessionStorage.getItem("authorization"));
        } catch (e) {
            return null;
        }

        return token;
    }

}