<template>
    <div id="app" class="page-container">
        <b-container id="app" class="content-container">
            <b-row>
                <b-col>
                    <PageHeader />
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <Navigation />
                </b-col>
            </b-row>
            <b-row  class="page">
                <b-col>
                    <Spinner v-if="isLoading" />
                    <router-view v-show="!isLoading" />
                </b-col>
            </b-row>
        </b-container>

    </div>
</template>



<script>
    // @ is an alias to /src
    import Spinner from '@/components/Spinner.vue'
    import Navigation from '@/components/Navigation.vue'
    import PageHeader from '@/components/PageHeader.vue'

    export default {
        name: 'App',
        components: {
            PageHeader,
            Navigation,
            Spinner
        },
        computed: {
            isLoading() {
                return this.$store.state.isLoading;
            }
        }
    }
</script>

<style scoped>


    .page-container {
        margin-top: 10px;
        padding-top: 30px;
        padding-bottom: 30px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .content-container {
        background-color: rgb(255,255,255) !important;
        background: rgb(255,255,255) !important;
        min-height: 92vh;
        max-width: 1080px;
        margin-left: auto;
        margin-right: auto;
        padding: 20px;
        border-radius: 5px;
    }


</style>

