
import { v4 as uuidv4 } from 'uuid';
import { IArticle } from '@/ts/IArticle'

export class Article implements IArticle {
    id: string;
    published: boolean;
    area_category: string;
    area: string;
    category: string;
    tags: Array<string>;
    name: string;
    title: string;
    date: Date;
    author: string;
    sections: Array<object>;

    constructor(area: string, string, title: string, author: string, tags?: Array<string>, sections?: Array<object>) {
        let category = "article"
        this.id = uuidv4();
        this.published = false;
        this.area_category = area + "_" + category;
        this.area = area;
        this.category = category;
        this.tags = tags || [];
        this.sections = this.sections || [];
        this.name = title.toLowerCase().replace(" ", "_");
        this.title = title;
        this.date = new Date();  
        this.author = author.length == 0 ? "Dave Cook" : author;
    }

    addTag(tag: string): void {
        this.tags.push(tag);
    }

    addSection(section: object): void {
        this.sections.push(section);
    }
}
