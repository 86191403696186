import Vue from 'vue'
import Vuex from 'vuex'
import { Article } from '@/ts/Article'
import { ArticleEditMoveSection } from '@/ts/Enums'
import { Authentication } from '@/ts/Authentication'

//import * as api from '@/assets/js/api.js'
const axios = require('axios').default

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        apiBaseUrl: getApiBaseUrl(),
        cv: null,
        kbList: [],
        blogList: [],
        articleList: [],
        newsList: [],
        unpublishedList: [],
        recentPostsList: [],
        articleEdit: new Article("", "", "", ""),
        articleEditMovingSection: 0,
        article: "",
        md5Hash: "",
        isLoading: false,
        isAdmin: Authentication.isAdmin(),
        isSignedIn: Authentication.isSignedIn(),
        currentView: "/"
    },
  mutations: {
      cv(state, cv) {
          state.cv = cv;
      },
      kbList(state, kbList) {
          state.kbList = kbList;
      },
      blogList(state, blogList) {
          state.blogList = blogList;
      },
      articleList(state, articleList) {
          state.articleList = articleList;
      },
      newsList(state, newsList) {
          state.newsList = newsList;
      },
      unpublishedList(state, unpublishedList) {
          state.unpublishedList = unpublishedList;
      },
      recentPostsList(state, recentPostsList) {
          state.recentPostsList = recentPostsList;
      },
      articleEdit(state, articleEdit) {
          state.articleEdit = articleEdit;
      },
      article(state, article) {
          state.article = article;
      },
      md5Hash(state, md5Hash) {
          state.md5Hash = md5Hash;
      },
      isLoading(state, value) {
          state.isLoading = value;
      },
      isSignedIn(state, value) {
          state.isSignedIn = value;
      },
      isAdmin(state, value) {
          state.isAdmin = value;
      },
      currentView(state, value) {
          state.currentView = value;
      },
      articleSetTitle(state, value) {
          state.articleEdit.title = value;
          state.articleEdit.name = value.toLowerCase().replaceAll(" ", "-");
      },
      articleSetArea(state, value) {
          state.articleEdit.area = value;
          state.articleEdit.area_category = value + "_article";
      },
      articleSetTags(state, value) {
          state.articleEdit.tags = value;
      },
      articleSetAuthor(state, value) {
          state.articleEdit.author = value;
      },
      articleSetDate(state, value) {
          state.articleEdit.date = value;
      },
      articleSetCategory(state, value) {
          state.articleEdit.category = value;
      },
      articleSetPublished(state, value) {
          state.articleEdit.published = value;
      },
      articleSetSection(state, { index, section }) {
          Vue.set(state.articleEdit.sections, index, section);
      },
      articleAddSection(state, section) {
          state.articleEdit.sections.push(section);
      },
      articleRemoveSection(state, index) {
          state.articleEdit.sections.splice(index, 1);
      },
      articleSetEditMovingSection(state, value) {
          state.articleEditMovingSection = value;
      },
      articleMoveSectionUp(state, index) {
          state.articleEditMovingSection = ArticleEditMoveSection.SectionOne;
          let sectionAbove = state.articleEdit.sections[index - 1];
          let activeSection = state.articleEdit.sections[index];

          state.articleEdit.sections.splice(index - 1, 2, activeSection, sectionAbove);
      },
      articleMoveSectionDown(state, index) {
          state.articleEditMovingSection = ArticleEditMoveSection.SectionOne;

          let sectionBelow = state.articleEdit.sections[index + 1];
          let activeSection = state.articleEdit.sections[index];

          state.articleEdit.sections.splice(index, 2, sectionBelow, activeSection );
      }
    },
  actions: {
      cv(context) {
          context.commit('isLoading', true);
          return axios.get(context.state.apiBaseUrl + '/cv')
              .then(res => {
                  let cvData = res.data;
                  context.commit('cv', cvData);
              }).catch(err => {
                  console.log(err);
              }).finally(function() {
                  context.commit('isLoading', false);
              })
      },
      kbList(context) {
          context.commit('isLoading', true);
          return axios.get(context.state.apiBaseUrl + '/kb')
              .then(res => {
                  let kbData = res.data;
                  context.commit('kbList', kbData);
              }).catch(err => {
                  console.log(err);
              }).finally(function () {
                  context.commit('isLoading', false);
              })
      },
      blogList(context) {
          context.commit('isLoading', true);
          return axios.get(context.state.apiBaseUrl + '/blog')
              .then(res => {
                  let blogData = res.data;
                  context.commit('blogList', blogData);
              }).catch(err => {
                  console.log(err);
              }).finally(function () {
                  context.commit('isLoading', false);
              })
      },
      articleList(context, params) {
          context.commit('isLoading', true);
          let path = context.state.apiBaseUrl + '/article/' + params.kb + '/' + params.blog + '/' + params.news

          if (params.query)
              path += '/' + params.query;
          else
              path += '/' + ' ';

          if (params.take)
              path += '/' + params.take;
          else
              path += '/25';

          return axios.get(path)
              .then(res => {
                  let articleData = res.data;
                  context.commit('articleList', articleData);
              }).catch(err => {
                  console.log(err);
              }).finally(function () {
                  context.commit('isLoading', false);
              })
      },
      newsList(context) {
          context.commit('isLoading', true);
          return axios.get(context.state.apiBaseUrl + '/news')
              .then(res => {
                  let newsData = res.data;
                  context.commit('newsList', newsData);
              }).catch(err => {
                  console.log(err);
              }).finally(function () {
                  context.commit('isLoading', false);
              })
      },
      unpublishedList(context) {
          context.commit('isLoading', true);

          const headers = {
              'x-functions-key': Authentication.functionCodeAdmin()
          }

          return axios.get(context.state.apiBaseUrl + '/unpublished', {
                headers: headers
              })
              .then(res => {
                  let unpublishedData = res.data;
                  context.commit('unpublishedList', unpublishedData);
              }).catch(err => {
                  console.log(err);
              }).finally(function () {
                  context.commit('isLoading', false);
              })
      },
      recentPostsList(context, take) {
          context.commit('isLoading', true);
          return axios.get(context.state.apiBaseUrl + '/recentposts/' + take)
              .then(res => {
                  let recentPostsData = res.data;
                  context.commit('recentPostsList', recentPostsData);
              }).catch(err => {
                  console.log(err);
              }).finally(function () {
                  context.commit('isLoading', false);
              })
      },
      article(context, identifier) {
          context.commit('isLoading', true);
          return axios.get(context.state.apiBaseUrl + '/' + identifier.area + '/article/' + identifier.name)
              .then(res => {
                  let articleData = res.data;
                  context.commit('article', articleData);
              }).catch(err => {
                  console.log(err);
              }).finally(function () {
                  context.commit('isLoading', false);
              })
      },
      articleById(context, id) {
          context.commit('isLoading', true);
          return axios.get(context.state.apiBaseUrl + '/article/' + id)
              .then(res => {
                  let articleData = res.data;
                  context.commit('articleEdit', articleData);
              }).catch(err => {
                  console.log(err);
              }).finally(function () {
                  context.commit('isLoading', false);
              })
      },
      articleDelete(context, { id, area }) {
          context.commit('isLoading', true);

          const headers = {
              'x-functions-key': Authentication.functionCodeAdmin()
          }

          return axios.delete(context.state.apiBaseUrl + '/article/' + id, {
                headers: headers
              })
              .then(res => {
                  if (res.data == true)
                      alert("Deleted");
                  else
                      alert("Deletion Failed !");
              }).catch(err => {
                  console.log(err);
              }).finally(function () {
                  context.commit('isLoading', false);
              })
      },
      articleSave(context) {

          context.commit('isLoading', true);

          const headers = {
              'x-functions-key': Authentication.functionCodeAdmin()
          }

          return axios.post(context.state.apiBaseUrl + '/article', context.state.articleEdit, {
                headers: headers
              })
              .then(res => {
                  if (res.data == true)
                      alert("Saved");
                  else
                      alert("Saving Failed !");
              }).catch(err => {
                  console.log(err);
              }).finally(function () {
                  context.commit('isLoading', false);
              })
      },
      signIn(context, credentials) {
          context.commit('isLoading', true);

          return new Promise((resolve, reject) => {

              return axios.post(context.state.apiBaseUrl + '/authentication', credentials)
                  .then(res => {
                      let token = res.data;
                      sessionStorage.setItem("authorization", token)

                      context.commit("isAdmin", Authentication.isAdmin)
                      context.commit("isSignedIn", true )

                      resolve("Success!");
                  }).catch(err => {
                      context.commit("isAdmin", false)
                      context.commit("isSignedIn", false)
                      console.log(err);
                      reject();
                  }).finally(function () {
                      context.commit('isLoading', false);
                  })
          });
      },
      articleEdit(context, articleEdit) {
          context.commit('articleEdit', articleEdit);
      },
      getMd5Hash(context, text) {
          return axios.get(context.state.apiBaseUrl + '/md5hash?text=' + text)
              .then(res => {
                  let md5Hash = res.data;
                  context.commit('md5Hash', md5Hash);
              }).catch(err => {
                  console.log(err);
              }).finally(function () {
              })
      }

    },
    getters: {
        articleEditSectionCount(state) {
            return state.articleEdit.sections.length;
        }
    }
})

function getApiBaseUrl() {
    if (window.location.hostname.toLowerCase() === 'localhost')
        return 'http://localhost:7071/api';
    else
        return 'https://api.pomple.com/api';
}


