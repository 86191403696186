import './assets/global.css'
import Vue from 'vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import App from './App.vue';
import router from './router'
import store from './store/store'
import moment from 'moment'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = true;
Vue.use(BootstrapVue);

//https://fontawesome.com/icons?d=gallery&p=2
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCopy, faTools, faFile, faInfo, faBlog, faCodeBranch, faNewspaper, faTrashAlt, faPlusSquare, faArrowUp, faArrowDown, faEdit, faWindowMaximize, faWindowMinimize } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faCopy, faTools, faFile, faInfo, faBlog, faCodeBranch, faNewspaper, faTrashAlt, faPlusSquare, faArrowUp, faArrowDown, faEdit, faWindowMaximize, faWindowMinimize)
Vue.component('font-awesome-icon', FontAwesomeIcon)

//https://github.com/highlightjs/highlight.js/blob/main/SUPPORTED_LANGUAGES.md
import hljs from 'highlight.js/lib/core';

import hljsRazor from 'highlightjs-cshtml-razor';
import javascript from 'highlight.js/lib/languages/javascript';
import csharp from 'highlight.js/lib/languages/csharp';
import dos from 'highlight.js/lib/languages/dos';
import powershell from 'highlight.js/lib/languages/powershell';
import sql from 'highlight.js/lib/languages/sql';
import hljsVuePlugin from "@highlightjs/vue-plugin";
import "highlight.js/styles/vs.css";

hljs.registerLanguage('highlightjs-cshtml-razor', hljsRazor);
hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('csharp', csharp);
hljs.registerLanguage('dos', dos);
hljs.registerLanguage('sql', sql);
hljs.registerLanguage('powershell', powershell);
Vue.use(hljsVuePlugin)

Vue.filter('formatDateMonthYear', function (value) {
    if (value) {
        return moment(String(value)).format('MMMM YYYY')
    }
});

Vue.filter('formatDateDayMonthYear', function (value) {
    if (value) {
        return moment(String(value)).format('MMMM DD, YYYY')
    }
});

Vue.filter('formatLanguage', function (value) {
    switch (value.toLowerCase()) {
        case "cs":
        case "csharp":
            return "C#"
        case "cshtml":
            return "CSHTML"
        case "cmd":
            return "CMD"
        case "sql":
            return "SQL"
    }

    return value.charAt(0).toUpperCase() + value.slice(1)
});



new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
