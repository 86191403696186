
    import { Component, Prop, Vue } from 'vue-property-decorator';

    @Component
    export default class Navigation extends Vue {
        private query: string = "";
        search(): void {
            if (this.query.trim().length > 0) {
                if (this.$route.name !== "search" || (this.$route.name === "search" && this.$route.params.query !== this.query)) {
                    this.$router.push({ name: "search", params: { query: this.query } });
                }
            }
        }

        signOut(): void {
            this.$store.commit("isAdmin", false);
            this.$store.commit("isSignedIn", false)
            sessionStorage.removeItem("authorization");
            this.$router.push("/");
        }

        get isAdmin() {
            return this.$store.state.isAdmin;
        }

        get isSignedIn() {
            return this.$store.state.isSignedIn;
        }
    }
