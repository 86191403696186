import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import store from './store/store'
import { Authentication } from '@/ts/Authentication'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes: [
    {
        path: '/',
        name: 'home',
        // route level code-splitting
        // this generates a separate chunk (home.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "home" */ './views/Home.vue')
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    },
    {
        path: '/mycv',
        name: 'mycv',
        // route level code-splitting
        // this generates a separate chunk (mycv.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "mycv" */ './views/MyCV.vue')
    },
    {
        path: '/signin',
        name: 'signin',
        // route level code-splitting
        // this generates a separate chunk (signin.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "signin" */ './views/SignIn.vue')
    },
    {
        path: '/kb',
        name: 'knowledgebase',
        // route level code-splitting
        // this generates a separate chunk (knowledgebase.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "knowledgebase" */ './views/KnowledgeBase.vue')
    },
    {
        path: '/news',
        name: 'news',
        // route level code-splitting
        // this generates a separate chunk (news.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "news" */ './views/News.vue')
    },
    {
        path: '/unpublished',
        name: 'unpublished',
        // route level code-splitting
        // this generates a separate chunk (unpublished.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "unpublished" */ './views/Unpublished.vue')
    },
    {
        path: '/search/:query',
        props: true,
        name: 'search',
        // route level code-splitting
        // this generates a separate chunk (search.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "search" */ './views/Search.vue')
    },
    {
        path: '/editor/:id?',
        props: true,
        name: 'editor',
        // route level code-splitting
        // this generates a separate chunk (editor.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "search" */ './views/Editor.vue')
    },
    {
        path: '/:area/article/:name',
        props: true,
        name: 'article',
        // route level code-splitting
        // this generates a separate chunk (article.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "article" */ './views/Article.vue')
    },
    {
        path: '/tips',
        name: 'tips',
        // route level code-splitting
        // this generates a separate chunk (tips.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "tips" */ './views/Tips.vue')
    },
    {
        path: '/blog',
        name: 'blog',
        // route level code-splitting
        // this generates a separate chunk (blog.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "blog" */ './views/Blogs.vue')
    }
    ],
})

router.beforeEach((to, from, next) => {
    store.state.isLoading = true;

    if (to.name !== "signin")
        store.state.currentView = to.fullPath;
    let isAdmin = Authentication.isSignedIn() && Authentication.isAdmin();
    if ((to.name == "editor" || to.name == "unpublished") && !isAdmin)
        next('/');
    else
        next();
})

router.afterEach(() => {
    store.state.isLoading = false;
})

export default router;